import packageJson from '../../package.json';
export const environment = {
	version: packageJson.version,
	production: false,
	test: true,
	develop: false,
	environment: 'test',
	service: 'demo',
	wsUrl: 'wss://apidemo.synapsia.ai/maestroAi',
	host: 'demo.mymaia.ai',
	apiHost: 'https://apidemo.synapsia.ai/',
	apiHostPublic: 'https://apidemo.synapsia.ai/'
};